<template>
  <div class="top">
    <div class="banner">
      <div class="inner">
        <div class="image">
          <img src="@/assets/img/img_avatar_product_foreground.png" />
        </div>
        <div class="dark"></div>
        <div class="text">
          <h1>{{ this.$t("product.detail.avatar_coordi") }}</h1>
          <h2>{{ this.$t("product.detail.avatar_coordi_desc_short") }}</h2>
        </div>
      </div>
    </div>
  </div>
  <List
    :data="product_data"
    :count="product_count"
    :is_for_avatar="true"
    :data_fully_loaded="data_fully_loaded"
    @load_more="this.load_product_data"
    @change_sort="this.change_sort"
    @change_filter="this.change_filter"
  ></List>
  <Spinner v-show="processing" />
</template>

<script>
import List from "@/views/product/List.vue";
export default {
  name: "AvatarProduct",
  components: {
    List,
  },
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.avatar_coordi"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.avatar_coordi"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],

      product_data: [],
      product_count: 0,

      data_fully_loaded: false,
      search_page: 1,
      search_sorting: {},
      search_filter: {},
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.load_product_data();
  },
  methods: {
    load_product_data: function () {
      if (this.data_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      // 필터에서 아바타 무조건 선택으로...
      if (
        typeof self.search_filter != "undefined" &&
        self.search_filter != null
      ) {
        self.search_filter.avatar = ["YES"];
      }

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/product/list",
          {
            filter: self.search_filter,
            sorting: self.search_sorting,
            page: self.search_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (self.search_page <= 1) {
            self.product_data = [];
          }

          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            self.product_data.push(data[i]);
          }

          if (data.length <= 0) {
            self.data_fully_loaded = true;
          } else {
            self.search_page++;
          }

          // 총 갯수 정보 헤더에서 가져오기
          try {
            let str_info = response.headers.wkt_extra_info;
            let extra_info = JSON.parse(str_info);
            self.product_count = parseInt(extra_info.total_count);
          } catch (e) {
            console.log(e);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //!-- axios
    }, //-- load_product_data

    change_sort: function (sorting) {
      this.search_sorting = sorting;

      this.data_fully_loaded = false;
      this.search_page = 1;

      this.load_product_data();
    }, //-- change_sort

    change_filter: function (filter) {
      this.search_filter = filter;

      this.data_fully_loaded = false;
      this.search_page = 1;

      this.load_product_data();
    },
  },
};
</script>

<style scoped>
.top {
  position: relative;
}
.top > .banner {
  position: relative;
  background-image: url("../../assets/img/img_avatar_product_background.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  height: 400px;
  max-height: 50vh;
}
.top > .banner > .inner {
  position: relative;
  width: 1200px;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;

  overflow: hidden;
}
.top > .banner > .inner > .text {
  position: absolute;
  left: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  color: #fff;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.45);

  margin: 0;
  padding: 0 1rem;
}
.top > .banner > .inner > .text > h1 {
  font-size: 1.8rem;
  font-weight: 700;
  padding: 0;
  margin: 0 0 1rem 0;
}
.top > .banner > .inner > .text > h2 {
  font-size: 1.4rem;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.top > .banner > .inner > .image {
  position: absolute;
  right: 0;
  bottom: 0;
}
.top > .banner > .inner > .image > img {
  display: block;
  height: 100%;
}

.top > .banner > .inner > .dark {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
}

@media (max-width: 800px) {
  .top > .banner > .inner > .dark {
    display: block;
  }
}
</style>
